<template>
  <Header
    title="Your trips"
    :subtitle="'you have ' + tripStore.trips?.length + ' trips in your account'"
  ></Header>
  <Link classes="button_with_sub create_trip" path="/trips/add">
    <div class="add">+</div>
    <div class="sub">add new trip</div>
  </Link>
  <div id="trips">
    <div
      @click="$router.push({ path: `/trips/${trip.id}` })"
      v-bind:class="trip.type_name + ' trip'"
      v-for="trip in tripStore.trips"
      :key="trip.id"
    >
      <div v-bind:class="trip.type_name + ' image'"></div>
      <div class="info">
        <div class="title">
          <div class="name">{{ trip.name }}</div>
          <div class="location">
            <div :class="`fflag ff-wave fflag-${trip.country}`"></div>
            {{ truncateText(trip.location_name, 25) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TripService from "@/services/trip.service";
import stringMixin from "@/mixins/string.mixin";

import { useTripStore } from '../../store/trip';

export default {
  name: "Trips",
  mixins: [stringMixin],
  data() {
    return {
      tripStore: useTripStore()
    }
},
  mounted() {
    // List Trips
    this.tripStore.getTrips();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
.create_trip
  width: 25rem
  margin-top: 3rem
  margin-bottom: 2rem

#trips
  display: flex
  flex-direction: row
  justify-content: center
  margin-top: 0
  text-align: center
  flex-wrap: wrap
  justify-content: flex-start
  padding-top: 2rem
  align-items: center
  gap: 3rem
  padding-bottom: 4rem

  @media (max-width: $media-mobile)
    height: 12rem

.trip
  display: flex
  border-radius: 20px
  box-shadow: 0 1px 3px 0 rgba(88, 102, 126, 0.4), 4px 4px 30px 0 rgba(88, 102, 126, 0.08)
  height: 22rem
  width: 30%
  max-width: 500px
  min-width: 300px
  background-color: white
  cursor: pointer
  flex-direction: column

  @media (max-width: $media-mobile)
    height: 18rem
    width: 100%
    min-width: 100%

  .image
    height: 14rem
    border-radius: 20px
    border-bottom-left-radius: 20px
    border-bottom-right-radius: 20px
    background-size: cover
    background-position: center center
    opacity: 0.8

    &.culture
      background-image: url(~@/assets/trip_types/culture.jpg)

    &.nature
      background-image: url(~@/assets/trip_types/nature.jpg)

    &.beach
      background-image: url(~@/assets/trip_types/beach.jpg)

    &.city
      background-image: url(~@/assets/trip_types/city.jpg)

    &.roadtrip
      background-image: url(~@/assets/trip_types/roadtrip.jpg)

    &.mountains
      background-image: url(~@/assets/trip_types/mountains.jpg)

    &.snow
      background-image: url(~@/assets/trip_types/snow.jpg)

    &.leisure
      background-image: url(~@/assets/trip_types/leisure.jpg)

  &:hover
    box-shadow: 1px 1px 20px #999

    .image
      opacity: 1

  .title
    font-family: 'Jost'
    text-align: left
    padding: 1.5rem 2rem

    @media (max-width: $media-mobile)
      padding-top: 1.4rem

    .name
      font-weight: 500
      line-height: 2rem
      font-size: 2rem
      color: #565656

      @media (max-width: $media-mobile)
        font-size: 2rem
        line-height: 2rem

    .location
      font-size: 1.6rem
      line-height: 2rem
      margin-top: 1rem
      font-weight: 500
      color: #959191
      display: flex

      .fflag
        border-radius: 60px
        margin-right: 1rem
        width: 20px
        height: 20px
        opacity: 0.7
</style>
